<template>
  <el-card :body-style="{ padding: '2px' }">
    <div>
      <el-form
        :model="listQuery"
        ref="loginForm"
        label-width="0px"
        :rules="rules"
        style="padding-top: 18px"
      >
        <el-row>
          <el-col :span="6" :offset="3">
            <el-form-item prop="searchDeveloper">
              <el-input
                v-model="listQuery.searchDeveloper"
                placeholder="开发商账号"
                style="width: 300px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" :offset="1">
            <el-form-item>
              <el-button @click="searchList">搜索</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </el-card>

  <el-card :body-style="{ padding: '2px' }">
    <div>
      <el-table
        :data="tableData"
        style="width: 100%"
        max-height="720px"
        :key="key1"
        v-loading="loading"
        :default-sort="defaultSort"
        @sort-change="sortChange"
        @row-click="goToGP"
        ref="table"
      >
        <el-table-column prop="manufacturerId" label="厂商ID" width="120" />
        <el-table-column prop="manufacturerName" label="厂商" width="200" />
        <el-table-column prop="name" label="应用名称" width="350" />
        <el-table-column prop="developer" label="开发商账号" width="250" />
        <el-table-column prop="appIcon" label="游戏ICON" width="120">
          <template v-slot="{ row }">
            <el-image
              style="width: 40px; height: 40px"
              :src="row.appIcon"
              fit="contain"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="category" label="类别" width="120" />
        <el-table-column
          prop="lastUpdate"
          label="更新日期"
          width="120"
          sortable="custom"
        >
          <template v-slot="{ row }">{{ dataFormat(row.lastUpdate) }}</template>
        </el-table-column>
        <el-table-column
          prop="download"
          label="下载量"
          width="120"
          sortable="custom"
        />
        <el-table-column
          prop="size"
          label="大小"
          width="100"
          sortable="custom"
        />
        <el-table-column
          prop="rate"
          label="评分"
          width="100"
          sortable="custom"
        />
        <el-table-column
          prop="totalRate"
          label="评分数"
          width="120"
          sortable="custom"
        />
        <el-table-column prop="isCollection" label="收藏" width="100">
          <template v-slot="{ row }">
            <el-button type="text" size="small" @click="operateCollection(row)">
              {{ collectionShow(row.isCollection) }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-card>
  <el-card :body-style="{ padding: '6px' }">
    <el-pagination
      v-model:currentPage="listQuery.page"
      :page-sizes="[10, 20, 50, 100]"
      v-model:page-size="listQuery.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalRecord"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </el-card>
</template>

<script>
import * as dayjs from "dayjs";
import enumValue from "../utils/enum";
import {
  addCollection,
  cancelCollection,
  getManufacturerAppList,
} from "../api/app";
const defaultPage = 1;
const defaultSize = 100;
export default {
  data() {
    return {
      defaultSort: { prop: "lastUpdate", order: "descending" },
      loading: false,
      totalRecord: undefined,
      key1: "",
      listQuery: {
        id: undefined,
        name: "",
        developer: "",
        searchDeveloper: "",
        page: defaultPage,
        pageSize: defaultSize,
        sort: enumValue.SortDesc,
        by: enumValue.appListByLastUpdate,
      },
      tableData: [],
    };
  },
  created() {
    if (this.$route.query.id){
          this.listQuery.id = Number(this.$route.query.id);
        }
    this.listQuery.name = this.$route.query.name;
    this.listQuery.developer = this.$route.query.developer;
    this.listQuery.searchDeveloper = this.$route.query.searchDeveloper;
    if (this.$route.query.page != undefined) {
      this.listQuery.page = Number(this.$route.query.page);
    } else {
      this.listQuery.page = defaultPage;
    }
    if (this.$route.query.pageSize != undefined) {
      this.listQuery.pageSize = Number(this.$route.query.pageSize);
    } else {
      this.listQuery.pageSize = defaultSize;
    }
    this.getList();
  },

  watch: {
    $route(to, from) {
      //监听相同路由下参数变化的时候，从而实现异步刷新
      //重新获取数据
      if (to.name == from.name) {
        if (this.$route.query.id){
          this.listQuery.id = Number(this.$route.query.id);
        }
        this.listQuery.name = this.$route.query.name;
        this.listQuery.developer = this.$route.query.developer;
        this.listQuery.searchDeveloper = this.$route.query.searchDeveloper;
        if (this.$route.query.page != undefined) {
          this.listQuery.page = Number(this.$route.query.page);
        } else {
          this.listQuery.page = defaultPage;
        }
        if (this.$route.query.pageSize != undefined) {
          this.listQuery.pageSize = Number(this.$route.query.pageSize);
        } else {
          this.listQuery.pageSize = defaultSize;
        }
        this.getList();
      }
    },
  },
  methods: {
    getList() {
      this.loading = true;
      getManufacturerAppList(this.listQuery)
        .then((resp) => {
          this.tableData = resp.data.data.records;
          this.totalRecord = resp.data.data.total;
          // this.key1 = Math.random();
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    searchList() {
      this.listQuery.page = defaultPage;
      this.getList();
    },
    // 显示收藏
    collectionShow(collection) {
      if (collection == "1") {
        return "已收藏";
      }
      return "未收藏";
    },
    // 收藏操作
    async operateCollection(row) {
      if (row.isCollection == "0") {
        await addCollection({ app_id: row.id });
        row.isCollection = "1";
      } else {
        await cancelCollection({ app_id: row.id });
        row.isCollection = "0";
      }
    },
    // 页面条数变化
    handleSizeChange(size) {
      this.listQuery.pageSize = size;
      this.$router.push({
        name: "manufacturerAppList",
        query: this.listQuery,
      });
    },
    // 翻页
    handleCurrentChange(page) {
      this.listQuery.page = page;
      this.$router.push({
        name: "manufacturerAppList",
        query: this.listQuery,
      });
    },
    // 时间格式化
    dataFormat(timestamp) {
      return dayjs.unix(timestamp).format("YYYY-MM-DD");
    },
    // 排序
    sortChange(obj) {
      var sortByMap = {
        lastUpdate: "last_update",
        download: "download",
        size: "size",
        rate: "rate",
        totalRate: "total_rate",
      };
      if (obj.order == "ascending") {
        this.listQuery.sort = "asc";
      } else if (obj.order == "descending") {
        this.listQuery.sort = "desc";
      } else {
        this.listQuery.sort = "";
      }
      if (obj.prop) {
        this.listQuery.by = sortByMap[obj.prop];
      } else {
        this.listQuery.by = "";
      }
      this.getList();
    },
    // 跳转gp
    goToGP(row) {
      const gpLink =
        "https://play.google.com/store/apps/details?id=" +
        row.packageName +
        "&hl=en";
      window.open(gpLink, "_blank");
    },
  },
};
</script>
<style scoped>
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.input-text :deep(.el-input__inner) {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 0px;
  width: 100%;
}
.el-card {
  margin: 4px;
  margin-left: 5px;
  margin-right: 5px;
}
</style>
